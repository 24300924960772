import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MunicipalityModel, PopulationDataModel, PopulationModel } from '../../../../core/models';
import { MunicipalityAggregatedDto, MunicipalityDto, PopulationDataDto, PopulationDto } from '../../dtos';


@Injectable({ providedIn: 'root' })
export class MunicipalityService {

    constructor(
        private http: HttpClient
    ) {
    }

    public getMunicipalities(): Observable<MunicipalityModel[]> {
        return this.http.get<MunicipalityAggregatedDto[]>(
            `${environment.portalApiUrl}/municipalities/aggregated`
        ).pipe(map((dtos: MunicipalityAggregatedDto[]) => dtos.map((dto: MunicipalityAggregatedDto) => this.mapFromAggregatedDto(dto))));
    }

    public getMunicipality(municipalityId: string): Observable<MunicipalityModel> {
        return this.http.get<MunicipalityAggregatedDto>(
            `${environment.portalApiUrl}/municipalities/aggregated/${municipalityId}`
        ).pipe(
            map((dto: MunicipalityAggregatedDto) => this.mapFromAggregatedDto(dto))
        );
    }

    public createMunicipality(municipality: MunicipalityModel): Observable<MunicipalityModel> {
        return this.http.post<MunicipalityAggregatedDto>(
            `${environment.portalApiUrl}/municipalities`,
            this.mapToDto(municipality)
        ).pipe(
            map((dto: MunicipalityAggregatedDto) => this.mapFromAggregatedDto(dto))
        );
    }

    public updateMunicipality(municipality: MunicipalityModel): Observable<MunicipalityModel> {
        return this.http.patch<MunicipalityAggregatedDto>(
            `${environment.portalApiUrl}/municipalities/${municipality.id}`,
            this.mapToDto(municipality)
        ).pipe(
            map((dto: MunicipalityAggregatedDto) => this.mapFromAggregatedDto(dto))
        );
    }

    public uploadMunicipalityPopulations(population: PopulationModel): Observable<MunicipalityModel[]> {
        return this.http.post<MunicipalityAggregatedDto[]>(
            `${environment.portalApiUrl}/municipalities/population`,
            this.mapToPopulationDto(population)
        ).pipe(
            map((dtos: MunicipalityAggregatedDto[]) => dtos.map((dto: MunicipalityAggregatedDto) => this.mapFromAggregatedDto(dto)))
        );
    }

    private mapFromAggregatedDto(dto: MunicipalityAggregatedDto): MunicipalityModel {
        return {
            id: dto.id,
            name: dto.name,
            municipalityKey: dto.municipalityKey,
            type: dto.type,
            localDistrict: {
                id: dto.localDistrict.id,
                name: dto.localDistrict.name
            },
            district: {
                id: dto.district.id,
                name: dto.district.name
            },
            federalState: {
                id: dto.federalState.id,
                code: dto.federalState.code,
                name: dto.federalState.name
            },
            netCenter: {
                id: dto.netCenter.id,
                name: dto.netCenter.name
            },
            netCenterManager: dto.netCenter.netCenterManager? {
                id: dto.netCenter.netCenterManager.id,
                description: dto.netCenter.netCenterManager.description,
                contact: {
                    kid: dto.netCenter.netCenterManager.kid,
                    givenName: dto.netCenter.netCenterManager.givenName,
                    lastName: dto.netCenter.netCenterManager.lastName,
                    phoneNumber: dto.netCenter.netCenterManager.phoneNumber,
                    mobileNumber: dto.netCenter.netCenterManager.mobileNumber,
                    faxNumber: dto.netCenter.netCenterManager.faxNumber,
                    email: dto.netCenter.netCenterManager.email
                }
            } : null,
            keyAccountManager: dto.keyAccountManager ? {
                id: dto.keyAccountManager.id,
                description: dto.keyAccountManager.description,
                contact: {
                    kid: dto.keyAccountManager.kid,
                    givenName: dto.keyAccountManager.givenName,
                    lastName: dto.keyAccountManager.lastName,
                    phoneNumber: dto.keyAccountManager.phoneNumber,
                    mobileNumber: dto.keyAccountManager.mobileNumber,
                    faxNumber: dto.keyAccountManager.faxNumber,
                    email: dto. keyAccountManager.email
                }
            } : null,
            population: dto.population ? {
                date: dto.population.date,
                count: dto.population.count
            } : null
        };
    }

    private mapToDto(model: MunicipalityModel): MunicipalityDto {
        return {
            id: model.id,
            name: model.name,
            municipalityKey: model.municipalityKey,
            federalStateId: model.federalState.id,
            localDistrictId: model.localDistrict.id,
            districtId: model.district.id,
            netCenterId: model.netCenter.id,
            keyAccountManagerId: model.keyAccountManager.id,
            type: model.type
        };
    }

    private mapToPopulationDataDto(model: PopulationDataModel): PopulationDataDto {
        return {
            municipalityKey: model.municipalityKey,
            count: model.count
        };
    }

    private mapToPopulationDto(model: PopulationModel): PopulationDto {
        return {
            date: model.date,
            populationData: model.populationData.map((data: PopulationDataModel) =>  this.mapToPopulationDataDto(data))
        };
    }
}
