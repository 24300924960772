import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, filter } from 'rxjs';
import { ProcedureModel } from '../models';
import { TDictionary } from '../utils/dictionary.util';


@Injectable({ providedIn: 'root' })
export class ProcedureState {
    public procedures$: Observable<ProcedureModel[]>;
    public procedure$: Observable<ProcedureModel>;

    private proceduresSource = new ReplaySubject<ProcedureModel[]>(1);
    private procedureSource = new BehaviorSubject<ProcedureModel>(null);
    private proceduresDict: TDictionary<ProcedureModel>;

    constructor() {
        this.proceduresDict = {};
        this.procedures$ = this.proceduresSource.asObservable();
        this.procedure$ = this.procedureSource.asObservable().pipe(filter((procedure: ProcedureModel) => procedure !== null));
    }

    public get procedure(): ProcedureModel {
        return this.procedureSource.value;
    }

    public setProcedures(procedures: ProcedureModel[]): void {
        this.proceduresToState(procedures);
        this.proceduresSource.next(this.proceduresFromState());
    }

    public setProcedure(procedure: ProcedureModel): void {
        this.proceduresDict[procedure.id] = procedure;

        this.procedureSource.next({ ...procedure });
        this.proceduresSource.next(this.proceduresFromState());
    }

    public addProcedure(procedure: ProcedureModel): void {
        this.proceduresDict[procedure.id] = procedure;
        this.proceduresSource.next(this.proceduresFromState());
    }

    public removeProcedure(procedureId: string): void {
        if (this.proceduresDict[procedureId]) {
            delete this.proceduresDict[procedureId];
            this.proceduresSource.next(this.proceduresFromState());
        }
    }

    private proceduresToState(procedures: ProcedureModel[]): void {
        this.proceduresDict = {};

        procedures.forEach(
            (procedure: ProcedureModel) => this.proceduresDict[procedure.id] = { ...procedure }
        );
    }

    private proceduresFromState(): ProcedureModel[] {
        return Object.values(this.proceduresDict);
    }
}
