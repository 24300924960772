export enum COMMODITY {
    POWER ='POWER',
    GAS = 'GAS'
}

export class CommodityLabelMapper {
    // Map the given commodity enum value to the corresponding icon label
    public static mapCommodityLabel(commodity: COMMODITY): string {
        return commodity === COMMODITY.GAS ? 'gas' : 'strom';
    }
}
