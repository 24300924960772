import { SelectItem } from "../items";
import { UserModel } from "../models";

export const mapToSelectItems = <T>(entities: T[], valueKey: string = 'id', textKey: string = 'name'): SelectItem[] => {
    return entities.map((entity: any) => ({ value: entity[valueKey], text: entity[textKey] }));
};

export const mapToUserSelectItems = (users: UserModel[]): SelectItem[] => {
    return users.map((user: UserModel) => ({ value: user.kid, text: `${ user.givenName} ${user.lastName} (${user.kid})` }));
};

export const filterSelectItems = (items: SelectItem[], filter: string): SelectItem[] => {
    return items.filter(
        (c: SelectItem) => c.text.toLowerCase().includes(filter)
    );
};
