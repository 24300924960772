// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phase-transition-dialog-header {
  align-items: baseline;
  -moz-column-gap: 16px;
       column-gap: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content auto;
  justify-content: space-between;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBoYXNlLXRyYW5zaXRpb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHFCQUFBO0VBQ0EscUJBQUE7T0FBQSxnQkFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtFQUNBLHVDQUFBO0VBQ0EsOEJBQUE7QUFDSiIsImZpbGUiOiJwaGFzZS10cmFuc2l0aW9uLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGhhc2UtdHJhbnNpdGlvbi1kaWFsb2ctaGVhZGVyIHtcbiAgICBhbGlnbi1pdGVtczogYmFzZWxpbmU7XG4gICAgY29sdW1uLWdhcDogMTZweDtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdyaWQtYXV0by1mbG93OiBjb2x1bW47XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtYXgtY29udGVudCBhdXRvO1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2Vlbjtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/feature-modules/phase-transition/styles/phase-transition.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,qBAAA;OAAA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uCAAA;EACA,8BAAA;AACJ;AAEA,wnBAAwnB","sourcesContent":[".phase-transition-dialog-header {\n    align-items: baseline;\n    column-gap: 16px;\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-columns: max-content auto;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
