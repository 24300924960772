export enum PHASE {
    QUANTITY_STRUCTURE_CREATION_START = 'QUANTITY_STRUCTURE_CREATION_START',
    QUANTITY_STRUCTURE_CREATION_END = 'QUANTITY_STRUCTURE_CREATION_END',
    PUBLICATION_START = 'PUBLICATION_START',
    PUBLICATION_END = 'PUBLICATION_END',
    INDICATIVE_PROCEDURE_START = 'INDICATIVE_PROCEDURE_START',
    INDICATIVE_PROCEDURE_END = 'INDICATIVE_PROCEDURE_END',
    FINAL_PROCEDURE_START = 'FINAL_PROCEDURE_START',
    FINAL_PROCEDURE_END = 'FINAL_PROCEDURE_END',
    REPORTING_START = 'REPORTING_START',
    ARCHIVED = 'ARCHIVED',
}
