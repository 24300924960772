const apiRootUrl = `${window.location.origin}`;

export const environment = {
    production: true,
    showEnvironmentHint: true,
    portalApiUrl: `${apiRootUrl}/api/portal-operation`,
    portalRoot: `${window.location.origin}/portal`,
    authConfig: {
        issuer: 'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/v2.0',
        clientId: '00fd2700-48e8-4b86-a704-7c6a0defaadd',
        responseType: 'code',
        redirectUri: `${window.location.origin}/portal/callback`,
        postLogoutRedirectUri: `${window.location.origin}/portal/logout`,
        scope: 'openid profile email api://00fd2700-48e8-4b86-a704-7c6a0defaadd/default',
        strictDiscoveryDocumentValidation: false,
        requireHttps: true,
        timeoutFactor: 0.9,
        showDebugInformation: true,
        clearHashAfterLogin: false,
        nonceStateSeparator: 'semicolon',
    },
    authModuleConfig: {
        resourceServer: {
            allowedUrls: [apiRootUrl],
            sendAccessToken: true,
        },
    },
};
