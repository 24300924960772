import { Injectable } from "@angular/core";
import { KeyAccountManagerModel } from "../models";
import { KeyAccountManagerService } from "../data-access/services";
import { Observable, tap } from "rxjs";
import { KeyAccountManagerState } from "../states";

@Injectable({ providedIn: 'root' })
export class KeyAccountManagerFacade {
    public keyAccountManagers$: Observable<KeyAccountManagerModel[]>;

    constructor(
        private keyAccountManagerService: KeyAccountManagerService,
        private keyAccountManagerState: KeyAccountManagerState
    ) {
       this.keyAccountManagers$ = this.keyAccountManagerState.keyAccountManagers$;
    }

    public loadKeyAccountManagers(): void {
        this.keyAccountManagerService.getKeyAccountManagers().subscribe(
            (keyAccountManagers: KeyAccountManagerModel[]) => this.keyAccountManagerState.setKeyAccountManagers(keyAccountManagers)
        );
    }

    public getKeyAccountManager(id: string): Observable<KeyAccountManagerModel> {
        return this.keyAccountManagerService.getKeyAccountManager(id).pipe(
            tap((kam: KeyAccountManagerModel) => this.keyAccountManagerState.setKeyAccountManager(kam))
        );
    }

    public updateKeyAccountManager(keyAccountManager: KeyAccountManagerModel): Observable<KeyAccountManagerModel> {
        return this.keyAccountManagerService.updateKeyAccountManager(keyAccountManager).pipe(
            tap((kam: KeyAccountManagerModel) => this.keyAccountManagerState.setKeyAccountManager(kam))
        );
    }

    public createKeyAccountManager(keyAccountManager: KeyAccountManagerModel): Observable<KeyAccountManagerModel> {
        return this.keyAccountManagerService.createKeyAccountManager(keyAccountManager).pipe(
            tap((kam: KeyAccountManagerModel) => this.keyAccountManagerState.addKeyAccountManager(kam))
        );
    }
}
