import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((resp) => this.handleError(resp)),
        );
    }

    private handleError(resp: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (resp.status === 401 || resp.status === 403) {
            this.router.navigate(['/unauthorized']);
        }
        return throwError(() => resp);
    }
}
