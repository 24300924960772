import { CpDepartment } from "@concession-portal/ng-dso-ui-components-fe";
import { DepartmentModel } from "./department.model";


export interface UserModel {
  kid: string;
  givenName: string;
  lastName: string;
  department: DepartmentModel;
}

export interface CurrentUser {
    kid: string;
    givenName: string;
    lastName: string;
    email: string;
    dso: string;
    department: CpDepartment;
    permissions: string[];
}

export const EMPTY_USER: UserModel = {
    kid: null,
    givenName: null,
    lastName: null,
    department: null,
}
