import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FederalStateModel } from '../../../../core/models';
import { FederalStateDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class FederalStateService {
    private cachedFederalStates: FederalStateModel[];

    constructor(private readonly http: HttpClient) {
    }

    public getFederalStates(): Observable<FederalStateModel[]> {
        if (this.cachedFederalStates) {
            return of(this.cachedFederalStates);
        }

        return this.http.get<FederalStateDto[]>(
            `${environment.portalApiUrl}/federal-states`
        ).pipe(
            map((dtos: FederalStateDto[]) => dtos.map((dto: FederalStateDto) => this.mapFromDto(dto))),
            tap((federalStates: FederalStateModel[]) => this.cachedFederalStates = federalStates)
        );
    }

    private mapFromDto(dto: FederalStateDto): FederalStateModel {
        return {
            id: dto.id,
            code: dto.code,
            name: dto.name
        };
    }
}
