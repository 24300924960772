import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

export const CONCESSION_KEY_VALIDATOR_REGEX: RegExp = /^\d+$/;

@Directive({
    selector: '[portalConcessionKeyValidator]',
    standalone: true,
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ConcessionKeyValidatorDirective,
        multi: true
    }]
})
export class ConcessionKeyValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {

        if (!control.value || CONCESSION_KEY_VALIDATOR_REGEX.test(control.value)) {
            return null;
        }

        return { concessionKey: true };
    }
}

