import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ConsultantModel } from '../../../../core/models';
import { ConsultantDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class ConsultantService {

    constructor(private http: HttpClient) {
    }

    public getConsultants(): Observable<ConsultantModel[]> {
        return this.http.get<ConsultantDto[]>(
            `${environment.portalApiUrl}/consultants`
        ).pipe(
            map((dtos: ConsultantDto[]) => dtos.map((dto: ConsultantDto) => this.mapFromDto(dto)))
        );
    }

    public getConsultant(consultantId: string ): Observable<ConsultantModel> {
        return this.http.get<ConsultantDto>(
            `${environment.portalApiUrl}/consultants/${consultantId}`
        ).pipe(
            map((dto: ConsultantDto) => this.mapFromDto(dto))
        );
    }

    public createConsultant(consultant: ConsultantModel): Observable<ConsultantModel> {
        return this.http.post<ConsultantDto>(
            `${environment.portalApiUrl}/consultants`, { ...consultant }
        ).pipe(
            map((dto: ConsultantDto) => this.mapFromDto(dto))
        );
    }

    private mapFromDto(dto: ConsultantDto): ConsultantModel {
        return {
            id: dto.id,
            name: dto.name
        };
    }
}
