import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ActivityModel } from '../../../../core/models';
import { formatDateToYYYYMMDD } from '../../../utils/date.util';
import { ActivityCreateDto, ActivityDto, ActivityUpdateDto, ActivityUpdatePhaseDto } from '../../dtos';


@Injectable({ providedIn: 'root' })
export class ActivityService {

    constructor(private http: HttpClient) {}

    public getActivities(): Observable<ActivityModel[]> {
        return this.http.get<ActivityDto[]>(
            `${environment.portalApiUrl}/activities`
        ).pipe(map((dtos: ActivityDto[]) => dtos.map((dto: ActivityDto) => this.mapFromDto(dto))));
    }

    public getActivity(activityId: string): Observable<ActivityModel> {
        return this.http.get<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}`
        ).pipe(map((dto: ActivityDto) => this.mapFromDto(dto)));
    }

    public createActivity(activity: ActivityModel): Observable<ActivityModel> {
        return this.http.post<ActivityDto>(
            `${environment.portalApiUrl}/activities`, this.mapToCreateDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapFromDto(dto)));
    }

    public updateActivity(activity: ActivityModel): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activity.id}`, this.mapToUpdateDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapFromDto(dto)));
    }

    public setPhase(activity: ActivityModel): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activity.id}/phase`, this.mapToUpdatePhaseDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapFromDto(dto)));
    }

    public endPhase(activityId: string): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}/end-current-phase`, {}
        ).pipe(map((dto: ActivityDto) => this.mapFromDto(dto)));
    }

    private mapFromDto(dto: ActivityDto): ActivityModel {
        return {
            id: dto.id,
            ownerKid: dto.ownerKid,
            commodity: dto.commodity,
            phase: dto.phase,
            phaseStartDate: dto.phaseStartDate,
            municipalityId: dto.municipality.id,
            gridOperatorId: dto.gridOperatorId,
            penaltyMax: dto.concessionContract?.penaltyMax,
            concessionContractId: dto.concessionContract?.id,
            concessionContractKey: dto.concessionContract?.concessionContractKey,
            state: dto.additionalMetaState,
            endDate: dto.endDate,
            notes: dto.notes
        };
    }

    private mapToCreateDto(model: ActivityModel): ActivityCreateDto {
        return {
            municipalityId: model.municipalityId,
            concessionContractId: model.concessionContractId,
            commodity: model.commodity,
            gridOperatorId: model.gridOperatorId,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase
        };
    }

    private mapToUpdateDto(model: ActivityModel): ActivityUpdateDto {
        return {
            gridOperatorId: model.gridOperatorId,
            notes: model.notes,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            additionalMetaState: model.state
        };
    }

    private mapToUpdatePhaseDto(model: ActivityModel): ActivityUpdatePhaseDto {
        return {
            gridOperatorId: model.gridOperatorId,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase
        };
    }
}
