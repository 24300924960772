import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { NetCenterModel } from '../../../models';
import { NetCenterDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class NetCenterService {

    constructor(private readonly http: HttpClient) {
    }

    public getNetCenters(): Observable<NetCenterModel[]> {
        return this.http.get<NetCenterDto[]>(
            `${environment.portalApiUrl}/net-centers`
        ).pipe(
            map((dtos: NetCenterDto[]) => dtos.map((dto: NetCenterDto) => this.mapFromDto(dto)))
        );
    }

    public getNetCenter(id: string): Observable<NetCenterModel> {
        return this.http.get<NetCenterDto>(
            `${environment.portalApiUrl}/net-centers/${id}`
        ).pipe(
            map((dto: NetCenterDto) => this.mapFromDto(dto))
        );
    }

    public createNetCenter(netCenter: NetCenterModel): Observable<NetCenterModel> {
        return this.http.post<NetCenterDto>(
            `${environment.portalApiUrl}/net-centers`,
            this.mapToDto(netCenter)
        ).pipe(
            map((dto: NetCenterDto) => this.mapFromDto(dto))
        );
    }

    public updateNetCenter(netCenter: NetCenterModel): Observable<NetCenterModel> {
        return this.http.put<NetCenterDto>(
            `${environment.portalApiUrl}/net-centers/${netCenter.id}`,
            this.mapToDto(netCenter),
        ).pipe(
            map((dto: NetCenterDto) => this.mapFromDto(dto))
        );

    }

    public assignNetCenterManager(netCenterId: string, netCenterManagerId: string): Observable<NetCenterModel> {
        return this.http.patch<NetCenterDto>(
            `${environment.portalApiUrl}/net-centers/${netCenterId}`,
            { netCenterManagerId }
        ).pipe(
            map((dto: NetCenterDto) => this.mapFromDto(dto))
        );
    }

    private mapFromDto(dto: NetCenterDto): NetCenterModel {
        return {
            id: dto.id,
            name: dto.name,
            netCenterManagerId: dto.netCenterManagerId
        };
    }

    private mapToDto(model: NetCenterModel): NetCenterDto {
        return {
            id: model.id,
            name: model.name,
            netCenterManagerId: model.netCenterManagerId
        };
    }
}
