import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { of } from "rxjs";

export function procedureTitleAlreadyExists(titles: string[]): AsyncValidatorFn {
    return (control: AbstractControl) => {
        if (titles.includes(control.value)) {
            return of({ procedureTitleExists: true });
        }

        return of(null);
    };
}