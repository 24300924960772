import { COMMODITY } from '../../../app/core/enums';

export const getCommodityName = (commodity: COMMODITY): string => {
    switch (commodity) {
        case COMMODITY.GAS:
            return 'Gas';

        case COMMODITY.POWER:
        default:
            return 'Strom';
    }
};

export const getCommodityIcon = (commodity: COMMODITY): string => {
    switch (commodity) {
        case COMMODITY.GAS:
            return 'gas';

        case COMMODITY.POWER:
        default:
            return 'strom';
    }
};

export const getCommodities = () : CommodityModel[] => {
    const commodities: CommodityModel[] = [];
    Object.keys(COMMODITY).forEach((key: COMMODITY) => {
        commodities.push({
            id: key,
            name: getCommodityName(key)
        })
    });

    return commodities;
}

export interface CommodityModel {
    id: COMMODITY;
    name: string;
}
