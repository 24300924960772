import { MUNICIPALITY_TYPE } from "../enums";
import { ContactModel } from "./contact.model";

export interface MunicipalityModel {
    id?: string;
    name: string;
    municipalityKey: string
    type: MUNICIPALITY_TYPE,
    localDistrict: {
        id: string | null;
        name?: string;
    },
    district: {
        id: string | null;
        name?: string;
    },
    federalState: {
        id: string | null;
        code?: string;
        name?: string;
    },
    netCenter: {
        id: string;
        name?: string;
    },
    netCenterManager?: {
        id: string | null;
        description?: string;
        contact?: ContactModel;
    } | null,
    keyAccountManager?: {
        id: string | null;
        description?: string;
        contact?: ContactModel;
    } | null,
    population?: {
        date: string,
        count: number
    } | null
}

export const EMPTY_MUNICIPALITY: MunicipalityModel = {
    name: null,
    municipalityKey: null,
    type: null,
    localDistrict: {
        id: null
    },
    district: {
        id: null
    },
    federalState: {
        id: null
    },
    netCenter: {
        id: null
    },
    netCenterManager: {
        id: null
    },
    keyAccountManager: {
        id: null
    },
    population: {
        date: null,
        count: null
    }
};
