import { COMMODITY, PHASE, STATE } from "../enums";

export interface ProcedureModel {
    id?: string;
    activities: ProcedureActivityModel[];
    commodity: COMMODITY;
    consultantId: string;
    endDate: string;
    gridOperatorId: string;
    ownerKid: string;
    phase: PHASE;
    state: STATE;
    suspectedCompetitor: string;
    title: string;
    basedOn?: string;
    phaseStartDate?: string;
    notes?: string;
}

export interface ProcedureActivityModel {
    id: string;
    concessionContractKey?: string;
    contractDocumentUri?: string;
    municipalityId?: string;
    municipalityName?: string;
    penaltyMax?: number | null; // Todo: @thorsten: jetzt ist hier numner, null und undefined erlaubt - können wir das eingrenzen?
    reportingDeadline?: string;
}
