export interface NetCenterManagerModel {
    id?: string;
    departmentId: string;
    email: string;
    faxNumber : string;
    givenName: string;
    lastName: string;
    mobileNumber: string;
    phoneNumber: string;
    kid: string;
    description: string;
}

export const EMPTY_NET_CENTER_MANAGER: NetCenterManagerModel = {
    id: null,
    departmentId: null,
    email: '',
    faxNumber : '',
    givenName: '',
    lastName: '',
    mobileNumber: '',
    phoneNumber: '',
    kid: '',
    description: '',
};