export interface NetCenterModel {
    id?: string;
    name: string;
    netCenterManagerId: string;
    netCenterManagerName?: string;
}

export const EMPTY_NET_CENTER: NetCenterModel = {
    id: null,
    name: '',
    netCenterManagerId: null
};
