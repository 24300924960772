import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ActivityService } from "../data-access/services";
import { STATE } from "../enums";
import { ActivityModel } from "../models";
import { ActivityState } from "../states";


@Injectable({ providedIn: 'root' })
export class ActivityFacade {
    public activities$: Observable<ActivityModel[]>;
    public activity$: Observable<ActivityModel>;

    constructor(
        private activityService: ActivityService,
        private activityState: ActivityState,
    ) {
        this.activities$ = this.activityState.activities$;
        this.activity$ = this.activityState.activity$;
    }

    public getCurrentActivity(): ActivityModel {
        return this.activityState.activity;
    }

    public loadActivities(): void {
        this.activityService.getActivities().subscribe(
            (activities: ActivityModel[]) => this.activityState.setActivities(activities)
        );
    }

    public loadActivity(activityId: string): void {
        this.activityService.getActivity(activityId).subscribe(
            (activity: ActivityModel) => this.activityState.setActivity(activity)
        );
    }

    public createActivity(activity: ActivityModel): Observable<ActivityModel> {
        return this.activityService.createActivity(activity).pipe(
            tap((activity: ActivityModel) => this.activityState.setActivity(activity))
        );
    }

    public startPhase(activity: ActivityModel): Observable<ActivityModel> {
        return this.activityService.setPhase(activity).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }

    public endPhase(activityId: string): Observable<ActivityModel> {
        return this.activityService.endPhase(activityId).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }

    public changeState(state: STATE): Observable<ActivityModel> {
        const activity = { ...this.activityState.activity, state };
        return this.updateActivity(activity);
    }

    public updateNotes(notes: string): Observable<ActivityModel> {
        const activity = { ...this.activityState.activity, notes };
        return this.updateActivity(activity);
    }

    public updateMetaData(ownerKid: string, endDate: string, gridOperatorId: string): Observable<ActivityModel> {
        const activity = { ...this.activityState.activity, ownerKid, endDate, gridOperatorId };
        return this.updateActivity(activity);
    }

    private updateActivity(activity: ActivityModel): Observable<ActivityModel> {
        return this.activityService.updateActivity(activity).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }
}
