import { Injectable } from "@angular/core";
import { LocalDistrictModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { LocalDistrictService } from "../data-access/services/local-district/local-district.service";
import { sortEntities } from "../utils/sort.util";

@Injectable({ providedIn: 'root' })
export class LocalDistrictFacade {
    public localDistricts$: Observable<LocalDistrictModel[]>;

    private localDistrictsSource = new ReplaySubject<LocalDistrictModel[]>(1);

    constructor(
        private localDistrictService: LocalDistrictService,
    ) {
        this.localDistricts$ = this.localDistrictsSource.asObservable();
    }

    public loadLocalDistricts(): void {
        this.localDistrictService.getLocalDistricts().subscribe(
            (localDistricts: LocalDistrictModel[]) => this.localDistrictsSource.next(sortEntities<LocalDistrictModel>(localDistricts))
        );
    }
}
