import { Injectable } from "@angular/core";
import { DepartmentModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class DepartmentState {
    public departments$: Observable<DepartmentModel[]>;

    private departmentsSource = new ReplaySubject<DepartmentModel[]>(1);
    private departmentsDict: TDictionary<DepartmentModel>;

    constructor() {
        this.departmentsDict = {};
        this.departments$ = this.departmentsSource.asObservable();
    }

    public setDepartments(departments: DepartmentModel[]): void {
        this.departmentsToState(departments);
        this.departmentsSource.next(this.departmentsFromState());
    }

    public setDepartment(department: DepartmentModel): void {
        this.departmentsDict[department.id] = department;
        this.departmentsSource.next(this.departmentsFromState());
    }

    public addDepartment(department: DepartmentModel): void {
        this.departmentsDict[department.id] = department;
        this.departmentsSource.next(this.departmentsFromState());
    }

    public updateDepartment(department: DepartmentModel): void {
        this.departmentsDict[department.id] = department;
        this.departmentsSource.next(this.departmentsFromState());
    }

    private departmentsToState(departments: DepartmentModel[]): void {
        this.departmentsDict = {};

        departments.forEach(
            (department: DepartmentModel) => this.departmentsDict[department.id] = { ...department }
        );
    }

    private departmentsFromState(): DepartmentModel[] {
        return Object.values(this.departmentsDict);
    }
}
