import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cpIntegerValidator } from '@concession-portal/ng-dso-ui-components-fe';
import { format } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { PhaseModel, PhaseReportingActivityModel, PhaseReportingBaseModel } from '../../../../core/models';

@Component({
  selector: 'cp-reporting-base-data',
  templateUrl: './reporting-base-data.component.html',
  styleUrls: ['./reporting-base-data.component.scss', '../../styles/phase-transition.scss']
})
export class ReportingBaseDataComponent implements OnInit {
    @Input()
    public data: PhaseModel;

    @Output()
    public dataUpdated = new EventEmitter<PhaseModel>();

    @Output()
    public dataValid = new EventEmitter<boolean>();


    public form: FormGroup;

    private penaltyMaxBackup: number = null;
    private readonly ngDestroy = new Subject<void>();

    public ngOnInit(): void {
        this.form = new FormGroup({
            contractEnd: new FormControl<string>(null, [Validators.required]),
            contractStart: new FormControl<string>(null, [Validators.required]),
            penaltyInfinite: new FormControl<boolean>(null),
            penaltyMax: new FormControl<number>(0, [Validators.required, cpIntegerValidator()]),
            reportingDeadline: new FormControl<string>(null, [Validators.required]),
        });

        this.penaltyMaxBackup = this.form.controls.penaltyMax.value;
        this.setPenaltyMaxFormControlDisabledState();

        this.initForm();
        this.bindFormChanges();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.unsubscribe();
    }

    private initForm(): void {
        this.form.controls.contractEnd.setValue(this.data.reporting.base.contractEnd, { emitEvent: false });
        this.form.controls.contractStart.setValue(this.data.reporting.base.contractStart, { emitEvent: false });
        this.form.controls.penaltyInfinite.setValue(this.data.reporting.base.penaltyInfinite, { emitEvent: false });
        this.form.controls.penaltyMax.setValue(this.data.reporting.base.penaltyMax ?? 0, { emitEvent: false });
        this.form.controls.reportingDeadline.setValue(this.data.reporting.base.reportingDeadline, { emitEvent: false });

        this.dataValid.emit(this.form.valid);
    }

    private bindFormChanges(): void {
        this.form.controls.penaltyInfinite.valueChanges.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe(
            () => this.setPenaltyMaxFormControlDisabledState()
        );

        this.form.valueChanges.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe(() => {
            const base: PhaseReportingBaseModel = {
                contractStart: format(new Date(this.form.controls.contractStart.value), 'yyyy-MM-dd'),
                contractEnd: format(new Date(this.form.controls.contractEnd.value), 'yyyy-MM-dd'),
                penaltyInfinite: this.form.controls.penaltyInfinite.value,
                penaltyMax: this.form.controls.penaltyMax.value,
                reportingDeadline: format(new Date(this.form.controls.reportingDeadline.value), 'yyyy-MM-dd')
            };

            const data: PhaseModel = {
                ...this.data,
                reporting: {
                    ...this.data.reporting,
                    base,
                    activities: this.data.reporting.activities.map((activity: PhaseReportingActivityModel) => ({
                        ...activity,
                        ownerKid: this.data.ownerKid,
                        gridOperatorId: this.data.gridOperatorId,
                        contractStart: base.contractStart,
                        contractEnd: base.contractEnd,
                        penaltyMax: base.penaltyMax,
                        penaltyInfinite: base.penaltyInfinite,
                        reportingDeadline: base.reportingDeadline
                    }))
                }
            };

            this.dataUpdated.emit(data);
            this.dataValid.emit(this.form.valid);
        });
    }

    private setPenaltyMaxFormControlDisabledState(): void {
        if (this.form.controls.penaltyInfinite.value) {
            this.penaltyMaxBackup = this.form.controls.penaltyMax.value;
            this.form.controls.penaltyMax.disable();
            this.form.controls.penaltyMax.setValue(null);
        } else {
            this.form.controls.penaltyMax.enable();
            this.form.controls.penaltyMax.setValue(this.penaltyMaxBackup);
        }
    }
}
