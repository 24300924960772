import { Injectable } from "@angular/core";
import { NetCenterManagerModel } from "../models";
import { NetCenterManagerService } from "../data-access/services";
import { Observable, tap } from "rxjs";
import { NetCenterManagerState } from "../states";

@Injectable({ providedIn: 'root' })
export class NetCenterManagerFacade {
    public netCenterManagers$: Observable<NetCenterManagerModel[]>;

    constructor(
        private netCenterManagerService: NetCenterManagerService,
        private netCenterManagerState: NetCenterManagerState
    ) {
        this.netCenterManagers$ = this.netCenterManagerState.netCenterManagers$;
    }

    public loadNetCenterManagers(): void {
        this.netCenterManagerService.getNetCenterManagers().subscribe(
            (netCenterManagers: NetCenterManagerModel[]) => {
                this.netCenterManagerState.setNetCenterManagers(netCenterManagers)
            }
        );
    }

    public getNetCenterManager(id: string): Observable<NetCenterManagerModel> {
        return this.netCenterManagerService.getNetCenterManager(id).pipe(
            tap((netCenterManager: NetCenterManagerModel) => this.netCenterManagerState.setNetCenterManager(netCenterManager))
        );
    }

    public createNetCenterManager(netCenterManager: NetCenterManagerModel): Observable<NetCenterManagerModel> {
        return this.netCenterManagerService.createNetCenterManager(netCenterManager).pipe(
            tap((ncm: NetCenterManagerModel) => this.netCenterManagerState.addNetCenterManager(ncm))
        );
    }

    public updateNetCenterManager(netCenterManager: NetCenterManagerModel): Observable<NetCenterManagerModel> {
        return this.netCenterManagerService.updateNetCenterManager(netCenterManager).pipe(
            tap((ncm: NetCenterManagerModel) => this.netCenterManagerState.setNetCenterManager(ncm))
        );
    }
}
