import { PHASE, STATE, COMMODITY } from "../enums";
import { PhaseReportingModel } from "./reporting-phase.model";

export interface PhaseModel {
    activityId: string;
    currentPhase: PHASE;
    nextPhase: PHASE;
    state: STATE;
    commodity: COMMODITY;
    commodityIcon: string;
    gridOperatorId: string;
    ownerKid: string;
    municipalityName?: string;
    startDate?: string;
    endDate?: string;
    procedure?: PhaseProcedureModel;
    reporting?: PhaseReportingModel
}

export interface PhaseProcedureModel {
    id?: string;
    consultantId: string;
    title: string;
    activityIds: string[];
    suspectedCompetitor: string;
    basedOnProcedureId?: string;
}

export const EMPTY_PHASE_PROCEDURE_MODEL: PhaseProcedureModel = {
    consultantId: null,
    title: null,
    activityIds: [],
    suspectedCompetitor: null
};