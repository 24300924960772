import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LocalDistrictModel } from '../../../../core/models';
import { LocalDistrictDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class LocalDistrictService {
    private cachedLocalDistricts: LocalDistrictModel[];

    constructor(private readonly http: HttpClient) {
    }

    public getLocalDistricts(): Observable<LocalDistrictModel[]> {
        return this.cachedLocalDistricts
            ? of(this.cachedLocalDistricts)
            : this.http.get<LocalDistrictDto[]>(`${environment.portalApiUrl}/local-districts`).pipe(
                map((dtos: LocalDistrictDto[]) => dtos.map((dto: LocalDistrictDto) => this.mapFromDto(dto))),
                tap((localDistricts: LocalDistrictModel[]) => this.cachedLocalDistricts = localDistricts)
            );
    }

    private mapFromDto(dto: LocalDistrictDto): LocalDistrictModel {
        return  {
            id: dto.id,
            districtId: dto.districtId,
            name: dto.name
        };
    }
}
