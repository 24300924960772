import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { DistrictModel } from '../../../../core/models';
import { DistrictDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class DistrictService {
    private cachedDistricts: DistrictModel[];

    constructor(private readonly http: HttpClient) {
    }

    public getDistricts(): Observable<DistrictModel[]> {
        return this.cachedDistricts
            ? of(this.cachedDistricts)
            : this.http.get<DistrictDto[]>(`${environment.portalApiUrl}/districts`).pipe(
                map((dtos: DistrictDto[]) => dtos.map((dto: DistrictDto) => this.mapFromDto(dto))),
                tap((districts: DistrictModel[]) => this.cachedDistricts = districts)
            );
    }

    private mapFromDto(dto: DistrictDto): DistrictModel {
        return {
            id: dto.id,
            name: dto.name,
            federalStateId: dto.federalStateId
        };
    }
}
