import { UserModel } from "../models";

export const applyUserFilter = (users: UserModel[], filter: string): UserModel[] => {
    filter = filter.toLowerCase();
    
    return users.filter((user: UserModel) => (
        user.givenName.toLowerCase().includes(filter) ||
        user.lastName.toLowerCase().includes(filter) ||
        user.kid.toLowerCase().includes(filter)
    ));
}