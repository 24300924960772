import { Injectable } from "@angular/core";
import { NetCenterModel } from "../models";
import { Observable, tap } from "rxjs";
import { NetCenterService } from "../data-access/services/net-center/net-center.service";
import { NetCenterState } from "../states";

@Injectable({ providedIn: 'root' })
export class NetCenterFacade {
    public netCenters$: Observable<NetCenterModel[]>;

    constructor(
        private netCenterService: NetCenterService,
        private netCenterState: NetCenterState
    ) {
        this.netCenters$ = this.netCenterState.netCenters$;
    }

    public loadNetCenters(): void {
        this.netCenterService.getNetCenters().subscribe(
            (netCenters: NetCenterModel[]) => this.netCenterState.setNetCenters(netCenters)
        );
    }

    public getNetCenter(netCenterId: string): Observable<NetCenterModel> {
        return this.netCenterService.getNetCenter(netCenterId).pipe(
            tap((netCenter: NetCenterModel) => this.netCenterState.setNetCenter(netCenter))
        );
    }

    public assignNetCenterManager(netCenterId: string, netCenterManagerId: string): Observable<NetCenterModel> {
        return this.netCenterService.assignNetCenterManager(netCenterId, netCenterManagerId).pipe(
            tap((netCenter: NetCenterModel) => this.netCenterState.setNetCenter(netCenter))
        );
    }
}