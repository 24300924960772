// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-procedure {
  min-width: 300px;
}

.start-procedure__form {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  -moz-column-gap: 64px;
       column-gap: 64px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0YXJ0LXByb2NlZHVyZS1waGFzZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGdCQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0EsaUNBQUE7RUFDQSxzQkFBQTtFQUNBLHFCQUFBO09BQUEsZ0JBQUE7QUFDSiIsImZpbGUiOiJzdGFydC1wcm9jZWR1cmUtcGhhc2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc3RhcnQtcHJvY2VkdXJlIHtcbiAgICBtaW4td2lkdGg6IDMwMHB4O1xufVxuXG4uc3RhcnQtcHJvY2VkdXJlX19mb3JtIHtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdyaWQtYXV0by1jb2x1bW5zOiBtaW5tYXgoMCwgMWZyKTtcbiAgICBncmlkLWF1dG8tZmxvdzogY29sdW1uO1xuICAgIGNvbHVtbi1nYXA6IDY0cHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/feature-modules/phase-transition/components/start-procedure-phase/start-procedure-phase.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,iCAAA;EACA,sBAAA;EACA,qBAAA;OAAA,gBAAA;AACJ;AAEA,onBAAonB","sourcesContent":[".start-procedure {\n    min-width: 300px;\n}\n\n.start-procedure__form {\n    display: grid;\n    grid-auto-columns: minmax(0, 1fr);\n    grid-auto-flow: column;\n    column-gap: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
