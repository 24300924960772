import { Injectable } from "@angular/core";
import { DepartmentModel } from "../models";
import { Observable, tap } from "rxjs";

import { DepartmentService } from "../data-access/services";
import { DepartmentState } from "../states/department.state";

@Injectable({ providedIn: 'root' })
export class DepartmentFacade {
    public departments$: Observable<DepartmentModel[]>;

    constructor(
        private departmentService: DepartmentService,
        private departmentState: DepartmentState
    ) {
        this.departments$ = this.departmentState.departments$;
    }

    public loadDepartments(): void {
        this.departmentService.getDepartments().subscribe(
            (departments: DepartmentModel[]) => {
                this.departmentState.setDepartments(departments);
            }
        );
    }

    public getDepartment(departmentId: string): Observable<DepartmentModel> {
        return this.departmentService.getDepartment(departmentId).pipe(
            tap((department: DepartmentModel) => this.departmentState.setDepartment(department))
        );
    }

    public createDepartment(department: DepartmentModel): Observable<DepartmentModel> {
        return this.departmentService.createDepartment(department).pipe(
            tap((department: DepartmentModel) => this.departmentState.addDepartment(department))
        );
    }

    public updateDepartment(department: DepartmentModel): Observable<DepartmentModel> {
        return this.departmentService.updateDepartment(department).pipe(
            tap((department: DepartmentModel) => this.departmentState.setDepartment(department))
        );
    }
}
