import { Injectable } from "@angular/core";
import { DistrictModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { sortEntities } from "../utils/sort.util";
import { DistrictService } from "../data-access/services";


@Injectable({ providedIn: 'root' })
export class DistrictFacade {
    public districts$: Observable<DistrictModel[]>;

    private districtsSource = new ReplaySubject<DistrictModel[]>(1);

    constructor(
        private districtService: DistrictService,
    ) {
        this.districts$ = this.districtsSource.asObservable();
    }

    public loadDistricts(): void {
        this.districtService.getDistricts().subscribe(
            (districts: DistrictModel[]) => this.districtsSource.next(sortEntities<DistrictModel>(districts))
        );
    }
}
