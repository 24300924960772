import { Injectable } from "@angular/core";
import { GridOperatorModel } from "../models";
import { GridOperatorService } from "../data-access/services";
import { map, Observable } from "rxjs";
import { GridOperatorState } from "../states";

@Injectable({ providedIn: 'root' })
export class GridOperatorFacade {
    public gridOperators$: Observable<GridOperatorModel[]>;

    constructor(
        private gridOperatorService: GridOperatorService,
        private gridOperatorState: GridOperatorState,
    ) {
        this.gridOperators$ = this.gridOperatorState.gridOperators$;
    }

    public loadGridOperators(): void {
        this.gridOperatorService.getGridOperators()
            .pipe(map((gridOperators: GridOperatorModel[]) => {
                const collator = new Intl.Collator('de');
                const compareFn = (a: GridOperatorModel, b: GridOperatorModel): number => collator.compare(a.name, b.name);
                return [...gridOperators].sort(compareFn)
            }))
            .subscribe(
            (gridOperators: GridOperatorModel[]) => this.gridOperatorState.setGridOperators(gridOperators),
        );
    }
}
