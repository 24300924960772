import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { GridOperatorModel } from '../../../../core/models';
import { GridOperatorDto } from '../../dtos';


@Injectable({ providedIn: 'root' })
export class GridOperatorService {
    private cachedGridOperators: GridOperatorModel[];

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getGridOperators(): Observable<GridOperatorModel[]> {
        return this.cachedGridOperators
            ? of(this.cachedGridOperators)
            : this.http.get<GridOperatorDto[]>(
                `${environment.portalApiUrl}/grid-operators`
            ).pipe(
                map((dtos: GridOperatorDto[]) => dtos.map((dto: GridOperatorDto) => this.mapFromDto(dto))),
                tap((gridOperators: GridOperatorModel[]) => this.cachedGridOperators = gridOperators)
            );
    }

    public getGridOperator(id: string): Observable<GridOperatorModel> {
        return this.getGridOperators()
            .pipe(
                map((gridOperators: GridOperatorModel[]) => gridOperators.find((gridOperator: GridOperatorModel) => gridOperator.id === id),
                ),
            );
    }

    private mapFromDto(dto: GridOperatorDto): GridOperatorModel {
        return {
            id: dto.id,
            name: dto.name,
            abbreviation: dto.abbreviation
        };
    }
}
