import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { NetCenterManagerModel } from '../../../../core/models';
import { NetCenterManagerDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class NetCenterManagerService {

    constructor(private http: HttpClient) {
    }

    public getNetCenterManagers(): Observable<NetCenterManagerModel[]> {
        return this.http.get<NetCenterManagerDto[]>(
            `${environment.portalApiUrl}/net-center-managers`
        ).pipe(
            map((dtos: NetCenterManagerDto[]) => dtos.map((dto: NetCenterManagerDto) => this.mapFromDto(dto)))
        );
    }

    public getNetCenterManager(id: string): Observable<NetCenterManagerModel> {
        return this.http.get<NetCenterManagerDto>(
            `${environment.portalApiUrl}/net-center-managers/${id}`
        ).pipe(
            map((dto: NetCenterManagerDto) => this.mapFromDto(dto))
        );
    }

    public createNetCenterManager(netCenterManager: NetCenterManagerModel): Observable<NetCenterManagerModel> {
        return this.http.post<NetCenterManagerModel>(
            `${environment.portalApiUrl}/net-center-managers`,
            this.mapToDto(netCenterManager),
        ).pipe(
            map((dto: NetCenterManagerDto) => this.mapFromDto(dto))
        );
    }

    public updateNetCenterManager(netCenterManager: NetCenterManagerModel): Observable<NetCenterManagerModel> {
        return this.http.put<NetCenterManagerModel>(
            `${environment.portalApiUrl}/net-center-managers/${netCenterManager.id}`,
            this.mapToDto(netCenterManager),
        ).pipe(
            map((dto: NetCenterManagerDto) => this.mapFromDto(dto))
        );
    }

    private mapFromDto(dto: NetCenterManagerDto): NetCenterManagerModel {
        return {
            id: dto.id,
            departmentId: dto.departmentId,
            email: dto.email,
            faxNumber : dto.faxNumber,
            givenName: dto.givenName,
            lastName: dto.lastName,
            mobileNumber: dto.mobileNumber,
            phoneNumber: dto.phoneNumber,
            kid: dto.kid,
            description: dto.description
        };
    }

    private mapToDto(model: NetCenterManagerModel): NetCenterManagerDto {
        return {
            id: model.id,
            departmentId: model.departmentId,
            email: model.email,
            faxNumber : model.faxNumber,
            givenName: model.givenName,
            lastName: model.lastName,
            mobileNumber: model.mobileNumber,
            phoneNumber: model.phoneNumber,
            kid: model.kid,
            description: model.description
        };
    }
}
