// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reporting-step-base__penalty {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50%;
  align-items: center;
  -moz-column-gap: 32px;
       column-gap: 32px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlcG9ydGluZy1iYXNlLWRhdGEuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxzQkFBQTtFQUNBLG1CQUFBO0VBQ0EscUJBQUE7T0FBQSxnQkFBQTtBQUNKIiwiZmlsZSI6InJlcG9ydGluZy1iYXNlLWRhdGEuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucmVwb3J0aW5nLXN0ZXAtYmFzZV9fcGVuYWx0eSB7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLWF1dG8tZmxvdzogY29sdW1uO1xuICAgIGdyaWQtYXV0by1jb2x1bW5zOiA1MCU7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBjb2x1bW4tZ2FwOiAzMnB4O1xufVxuXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/feature-modules/phase-transition/components/reporting-base-data/reporting-base-data.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;OAAA,gBAAA;AACJ;AAEA,gkBAAgkB","sourcesContent":[".reporting-step-base__penalty {\n    display: grid;\n    grid-auto-flow: column;\n    grid-auto-columns: 50%;\n    align-items: center;\n    column-gap: 32px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
