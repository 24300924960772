import { Injectable } from "@angular/core";
import { GridOperatorModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class GridOperatorState {
    public gridOperators$: Observable<GridOperatorModel[]>;

    private gridOperatorsSource = new ReplaySubject<GridOperatorModel[]>(1);
    private gridOperatorsDict: TDictionary<GridOperatorModel>;

    constructor() {
        this.gridOperatorsDict = {};
        this.gridOperators$ = this.gridOperatorsSource.asObservable();
    }

    public setGridOperators(keyAccountManagers: GridOperatorModel[]): void {
        this.gridOperatorsToState(keyAccountManagers);
        this.gridOperatorsSource.next(this.gridOperatorsFromState());
    }

    private gridOperatorsToState(gridOperators: GridOperatorModel[]): void {
        this.gridOperatorsDict = {};

        gridOperators.forEach(
            (gridOperator: GridOperatorModel) => this.gridOperatorsDict[gridOperator.id] = { ...gridOperator },
        );
    }

    private gridOperatorsFromState(): GridOperatorModel[] {
        return Object.values(this.gridOperatorsDict);
    }
}
