import { Injectable } from "@angular/core";
import { NetCenterModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class NetCenterState {
    public netCenters$: Observable<NetCenterModel[]>;

    private netCentersSource = new ReplaySubject<NetCenterModel[]>(1);
    private netCentersDict: TDictionary<NetCenterModel>;

    constructor() {
        this.netCentersDict = {};
        this.netCenters$ = this.netCentersSource.asObservable();
    }

    public setNetCenters(netCenters: NetCenterModel[]): void {
        this.netCentersToState(netCenters);
        this.netCentersSource.next(this.netCentersFromState());
    }

    public setNetCenter(netCenter: NetCenterModel): void {
        this.netCentersDict[netCenter.id] = netCenter;
        this.netCentersSource.next(this.netCentersFromState());
    }

    public addNetCenter(netCenter: NetCenterModel): void {
        this.netCentersDict[netCenter.id] = netCenter;
        this.netCentersSource.next(this.netCentersFromState());
    }

    private netCentersToState(netCenters: NetCenterModel[]): void {
        this.netCentersDict = {};

        netCenters.forEach(
            (netCenter: NetCenterModel) => this.netCentersDict[netCenter.id] = { ...netCenter }
        );
    }

    private netCentersFromState(): NetCenterModel[] {
        return Object.values(this.netCentersDict);
    }
}
