import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DepartmentModel } from '../../../../core/models';
import { DepartmentDto } from '../../dtos';



@Injectable({ providedIn: 'root' })
export class DepartmentService {
    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getDepartments(): Observable<DepartmentModel[]> {
        return this.http.get<DepartmentDto[]>(
            `${environment.portalApiUrl}/departments`
        ).pipe(
            map((dtos: DepartmentDto[]) => dtos.map((dto: DepartmentDto) => this.mapFromDto(dto)))
        );
    }

    public getDepartment(departmentId: string): Observable<DepartmentModel> {
        return this.http.get<DepartmentDto>(
            `${environment.portalApiUrl}/departments/${departmentId}`
        ).pipe(
            map((dto: DepartmentDto) => this.mapFromDto(dto))
        );
    }

    public createDepartment(department: DepartmentModel): Observable<DepartmentModel> {
        return this.http.post<DepartmentDto>(
            `${environment.portalApiUrl}/departments`,
            this.matToDto(department),
        ).pipe(
            map((dto: DepartmentDto) => this.mapFromDto(dto))
        );
    }

    public updateDepartment(department: DepartmentModel): Observable<DepartmentModel> {
        return this.http.put<DepartmentDto>(
            `${environment.portalApiUrl}/departments/${department.id}`,
            this.matToDto(department),
        ).pipe(
            map((dto: DepartmentDto) => this.mapFromDto(dto))
        );
    }

    private mapFromDto(dto: DepartmentDto): DepartmentModel {
        return {
            id: dto.id,
            abbreviation: dto.abbreviation,
            name: dto.name
        };
    }

    private matToDto(model: DepartmentModel): DepartmentDto {
        return {
            id: model.id,
            abbreviation: model.abbreviation,
            name: model.name
        };
    }
}
