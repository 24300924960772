import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { buildInfo } from '../environments/buildinfo';


@Component({
    selector: 'cp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent{

    constructor(
        private meta: Meta,
    ) {
        this.meta.addTag({
            name: 'buildinfo',
            content: JSON.stringify(buildInfo),
        });
    }
}
