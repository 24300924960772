import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PhaseReportingActivityModel, ProcedureActivityModel, ProcedureModel } from '../../../../core/models';
import { formatDateToYYYYMMDD } from '../../../utils/date.util';
import { ProcedureActivityDto, ProcedureCreateDto, ProcedureDto, ProcedureUpdateDto, ProcedureUpdatePhaseDto, ReportingPhaseDto } from '../../dtos';


@Injectable({ providedIn: 'root' })
export class ProcedureService {

    constructor(private http: HttpClient) {}

    public getProcedures(): Observable<ProcedureModel[]> {
        return this.http.get<ProcedureDto[]>(
            `${environment.portalApiUrl}/procedures`
        ).pipe(map((dtos: ProcedureDto[]) => dtos.map((dto: ProcedureDto) => this.mapFromDto(dto))));
    }

    public getProcedure(procedureId: string): Observable<ProcedureModel> {
        return this.http.get<ProcedureDto>(
            `${environment.portalApiUrl}/procedures/${procedureId}`
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    public createProcedure(procedure: ProcedureModel): Observable<ProcedureModel> {
        return this.http.post<ProcedureDto>(
            `${environment.portalApiUrl}/procedures`, this.mapToProcedureCreateDto(procedure)
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    public updateProcedure(procedure: ProcedureModel): Observable<ProcedureModel> {
        return this.http.put<ProcedureDto>(
            `${environment.portalApiUrl}/procedures/${procedure.id}`, this.mapToProcedureUpdateDto(procedure)
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    public setPhase(procedure: ProcedureModel): Observable<ProcedureModel> {
        return this.http.put<ProcedureDto>(
            `${environment.portalApiUrl}/procedures/${procedure.id}/phase`, this.mapToProcedureUpdatePhaseDto(procedure)
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    public endPhase(procedureId: string): Observable<ProcedureModel> {
        return this.http.put<ProcedureDto>(
            `${environment.portalApiUrl}/procedures/${procedureId}/end-current-phase`, {}
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    public startReportingPhase(procedureId: string, activities: PhaseReportingActivityModel[]): Observable<ProcedureModel> {
        const payload: ReportingPhaseDto[] = activities.map((activity: PhaseReportingActivityModel) => this.mapToReportingPhaseDto(activity));

        return this.http.put<ProcedureDto>(
            `${environment.portalApiUrl}/procedures/${procedureId}/phase-reporting`, payload
        ).pipe(map((dto: ProcedureDto) => this.mapFromDto(dto)));
    }

    private mapFromDto(dto: ProcedureDto): ProcedureModel {
        return {
            id: dto.id,
            commodity: dto.commodity,
            ownerKid: dto.ownerKid,
            phase: dto.phase,
            phaseStartDate: dto.phaseStartDate,
            title: dto.title,
            activities: dto.activities.map((dto: ProcedureActivityDto) => this.mapProcedureActivityFromDto(dto)),
            state: dto.additionalMetaState,
            consultantId: dto.consultantId,
            gridOperatorId: dto.gridOperatorId,
            suspectedCompetitor: dto.suspectedCompetitor,
            endDate: formatDateToYYYYMMDD(dto.endDate),
            notes: dto.notes
        };
    }

    private mapToProcedureCreateDto(model: ProcedureModel): ProcedureCreateDto {
        return {
            commodity: model.commodity,
            title: model.title,
            suspectedCompetitor: model.suspectedCompetitor,
            consultantId: model.consultantId,
            gridOperatorId: model.gridOperatorId,
            basedOn: model.basedOn,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase,
            activities: model.activities.map((activity: ProcedureActivityModel) => activity.id)
        };
    }

    private mapToProcedureUpdateDto(model: ProcedureModel): ProcedureUpdateDto {
        return {
            title: model.title,
            suspectedCompetitor: model.suspectedCompetitor,
            consultantId: model.consultantId,
            gridOperatorId: model.gridOperatorId,
            notes: model.notes,
            additionalMetaState: model.state,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            activities: model.activities.map((activity: ProcedureActivityModel) => activity.id)
        };
    }

    private mapToProcedureUpdatePhaseDto(model: ProcedureModel): ProcedureUpdatePhaseDto {
        return {
            gridOperatorId: model.gridOperatorId,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase
        };
    }

    private mapProcedureActivityFromDto(dto: ProcedureActivityDto): ProcedureActivityModel {
        return {
            id: dto.id,
            concessionContractKey: dto.concessionContract?.concessionContractKey,
            contractDocumentUri: dto.concessionContract?.contractDocumentUri,
            municipalityId: dto.municipality.id,
            municipalityName: dto.municipality.name,
            reportingDeadline: dto.reportingDeadline,
            penaltyMax: dto.penaltyMax,
        };
    }

    private mapToReportingPhaseDto(activity: PhaseReportingActivityModel): ReportingPhaseDto {
        return {
            activityId: activity.activityId,
            reportingDeadline: activity.reportingDeadline,
            concessionContractDocumentUri: activity.contractDocumentUri,
            concessionContractStart: activity.contractStart,
            concessionContractEnd: activity.contractEnd,
            concessionContractKey: activity.contractKey,
            penaltyMax: activity.penaltyMax,
            ownerKid: activity.ownerKid,
            gridOperatorId: activity.gridOperatorId
        };
    }
}
