import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, filter } from 'rxjs';
import { ActivityModel } from '../models';
import { TDictionary } from '../utils/dictionary.util';


@Injectable({ providedIn: 'root' })
export class ActivityState {
    public activities$: Observable<ActivityModel[]>;
    public activity$: Observable<ActivityModel>;

    private activitiesSource = new ReplaySubject<ActivityModel[]>(1);
    private activitySource = new BehaviorSubject<ActivityModel>(null);
    private activitiesDict: TDictionary<ActivityModel>;

    constructor() {
        this.activitiesDict = {};
        this.activities$ = this.activitiesSource.asObservable();
        this.activity$ = this.activitySource.asObservable().pipe(filter((activity: ActivityModel) => activity !== null));
    }

    public get activity(): ActivityModel {
        return this.activitySource.value;
    }

    public setActivities(activities: ActivityModel[]): void {
        this.activitiesToState(activities);
        this.activitiesSource.next(this.activitiesFromState());
    }

    public setActivity(activity: ActivityModel): void {
        this.activitiesDict[activity.id] = activity;

        this.activitySource.next({ ...activity });
        this.activitiesSource.next(this.activitiesFromState());
    }

    private activitiesToState(activities: ActivityModel[]): void {
        this.activitiesDict = {};

        activities.forEach(
            (activity: ActivityModel) => this.activitiesDict[activity.id] = { ...activity }
        );
    }

    private activitiesFromState(): ActivityModel[] {
        return Object.values(this.activitiesDict);
    }
}
