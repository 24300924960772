import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { MunicipalityService } from "../data-access/services";
import { MunicipalityModel, PopulationModel } from "../models";
import { MunicipalityState } from "../states";

@Injectable({ providedIn: 'root' })
export class MunicipalityFacade {

    public municipalities$: Observable<MunicipalityModel[]>;

    constructor(
        private municipalityService: MunicipalityService,
        private municipalityState: MunicipalityState
    ) {
        this.municipalities$ = this.municipalityState.municipalities$;
    }

    public loadMunicipalities(): void {
        this.municipalityService.getMunicipalities().subscribe(
            (municipalities: MunicipalityModel[]) => {
                const collator = new Intl.Collator('de');
                const compareFn = (a: MunicipalityModel, b: MunicipalityModel): number => collator.compare(a.name, b.name);

                this.municipalityState.setMunicipalities([...municipalities].sort(compareFn));
            }
        );
    }

    public getMunicipality(municipalityId: string): Observable<MunicipalityModel> {
        return this.municipalityService.getMunicipality(municipalityId).pipe(
            tap((municipality: MunicipalityModel) => this.municipalityState.setMunicipality(municipality))
        );
    }

    public updateMunicipality(muncipality: MunicipalityModel): Observable<MunicipalityModel> {
        return this.municipalityService.updateMunicipality(muncipality).pipe(
            tap((m: MunicipalityModel) => this.municipalityState.setMunicipality(m))
        );
    }

    public createMunicipality(municipality: MunicipalityModel): Observable<MunicipalityModel> {
        return this.municipalityService.createMunicipality(municipality).pipe(
            tap((m: MunicipalityModel) => this.municipalityState.addMuncipality(m))
        );
    }

    public uploadMunicipalityPopulations(populations: PopulationModel): Observable<MunicipalityModel[]> {
        return this.municipalityService.uploadMunicipalityPopulations(populations).pipe(
            tap((municipalites: MunicipalityModel[]) => this.municipalityState.updateMunicipalites([...municipalites]))
        );
    }
}
