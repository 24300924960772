import { Injectable } from "@angular/core";
import { ConsultantModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class ConsultantState {
    public consultants$: Observable<ConsultantModel[]>;

    private consultantsSource = new ReplaySubject<ConsultantModel[]>(1);
    private consultantsDict: TDictionary<ConsultantModel>;

    constructor() {
        this.consultantsDict = {};
        this.consultants$ = this.consultantsSource.asObservable();
    }

    public setConsultants(consultants: ConsultantModel[]): void {
        this.consultantsToState(consultants);
        this.consultantsSource.next(this.consultantsFromState());
    }

    public addConsultant(consultant: ConsultantModel): void {
        this.consultantsDict[consultant.id] = consultant;
        this.consultantsSource.next(this.consultantsFromState());
    }

    private consultantsToState(consultants: ConsultantModel[]): void {
        this.consultantsDict = {};

        consultants.forEach(
            (consultant: ConsultantModel) => this.consultantsDict[consultant.id] = { ...consultant }
        );
    }

    private consultantsFromState(): ConsultantModel[] {
        return Object.values(this.consultantsDict);
    }
}
