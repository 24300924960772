import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { KeyAccountManagerModel } from '../../../../core/models';
import { KeyAccountManagerDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class KeyAccountManagerService {

    constructor(private readonly http: HttpClient) {
    }

    public getKeyAccountManagers(): Observable<KeyAccountManagerModel[]> {
        return this.http.get<KeyAccountManagerDto[]>(
            `${environment.portalApiUrl}/key-account-managers`
        ).pipe(
            map((dtos: KeyAccountManagerDto[]) => dtos.map((dto: KeyAccountManagerDto) => this.mapFromDto(dto)))
        );
    }

    public getKeyAccountManager(keyAccountManagerId: string): Observable<KeyAccountManagerModel> {
        return this.http.get<KeyAccountManagerDto>(
            `${environment.portalApiUrl}/key-account-managers/${keyAccountManagerId}`
        ).pipe(
            map((dto: KeyAccountManagerDto) => this.mapFromDto(dto))
        );
    }

    public createKeyAccountManager(keyAccountManager: KeyAccountManagerModel): Observable<KeyAccountManagerModel> {
        return this.http.post<KeyAccountManagerModel>(
            `${environment.portalApiUrl}/key-account-managers`,
            this.mapToDto(keyAccountManager)
        ).pipe(
            map((dto: KeyAccountManagerDto) => this.mapFromDto(dto))
        );
    }

    public updateKeyAccountManager(keyAccountManager: KeyAccountManagerModel): Observable<KeyAccountManagerModel> {
        return this.http.put<KeyAccountManagerDto>(
            `${environment.portalApiUrl}/key-account-managers/${keyAccountManager.id}`,
            this.mapToDto(keyAccountManager)
        ).pipe(
            map((dto: KeyAccountManagerDto) => this.mapFromDto(dto))
        );
    }

    private mapFromDto(dto: KeyAccountManagerDto): KeyAccountManagerModel {
        return {
            id: dto.id,
            kid: dto.kid,
            departmentId: dto.departmentId,
            description: dto.description,
            givenName: dto.givenName,
            lastName: dto.lastName,
            phoneNumber: dto.phoneNumber,
            mobileNumber: dto.mobileNumber,
            faxNumber: dto.faxNumber,
            email: dto.email
        };
    }

    private mapToDto(model: KeyAccountManagerModel): KeyAccountManagerDto {
        return {
            id: model.id,
            kid: model.kid,
            departmentId: model.departmentId,
            description: model.description,
            givenName: model.givenName,
            lastName: model.lastName,
            phoneNumber: model.phoneNumber,
            mobileNumber: model.mobileNumber,
            faxNumber: model.faxNumber,
            email: model.email
        };
    }
}
