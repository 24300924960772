import { MUNICIPALITY_TYPE, MunicipalityTypeMapper } from "./core/enums";

export const DEFAULT_LANGUAGE = 'de';

export const PROCEDURE_TITLE_VALIDATOR_PATTERN = /^([\da-z \-_äöüß\(\)]+)$/i;
export const MAX_SHORT_DESCRIPTION_LENGTH = 1000;
export const MAX_PHONE_NUMBER_LENGTH = 20;
export const MAX_EMAIL_LENGTH = 100;
export const MAX_NAME_LENGTH = 100;
export const MUNICIPALITY_KEY_PATTERN = '^(0[1-9]|1[0-6])\\d{6}$';

export const MUNICIPALITY_TYPE_LIST_OPTION: Array<{key: MUNICIPALITY_TYPE; label: string}> = [
    {
        key: MUNICIPALITY_TYPE.CITY,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.CITY),
    },
    {
        key: MUNICIPALITY_TYPE.DISTRICT,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.DISTRICT),
    },
    {
        key: MUNICIPALITY_TYPE.HAMLET,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.HAMLET),
    },
    {
        key: MUNICIPALITY_TYPE.HANSEATIC_CITY,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.HANSEATIC_CITY),
    },
    {
        key: MUNICIPALITY_TYPE.UNIFIED_MUNICIPALITY,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.UNIFIED_MUNICIPALITY),
    },
    {
        key: MUNICIPALITY_TYPE.STATE_CAPITAL,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.STATE_CAPITAL),
    },
    {
        key: MUNICIPALITY_TYPE.UNINCORPORATED_AREA,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.UNINCORPORATED_AREA),
    },
    {
        key: MUNICIPALITY_TYPE.MUNICIPALITY,
        label: MunicipalityTypeMapper.map(MUNICIPALITY_TYPE.MUNICIPALITY),
    },
];

