import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { MunicipalityModel } from "../models";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class MunicipalityState {
    public municipalities$: Observable<MunicipalityModel[]>;
    public municipality$: Observable<MunicipalityModel>;

    private municipalitiesSource = new ReplaySubject<MunicipalityModel[]>(1);
    private municipalitySource = new ReplaySubject<MunicipalityModel>(1);
    private municipalitiesDict: TDictionary<MunicipalityModel>;

    constructor() {
        this.municipalitiesDict = {};
        this.municipalities$ = this.municipalitiesSource.asObservable();
        this.municipality$ = this.municipalitySource.asObservable();
    }

    public setMunicipalities(municipalities: MunicipalityModel[]): void {
        this.setMunicipalitiesToState(municipalities);
        this.municipalitiesSource.next(this.getMunicipalitiesFromState());
    }

    public setMunicipality(municipality: MunicipalityModel): void {
        this.municipalitiesDict[municipality.id] = municipality;

        this.municipalitySource.next({ ...municipality });
        this.municipalitiesSource.next(this.getMunicipalitiesFromState());
    }

    public addMuncipality(municipality: MunicipalityModel): void {
        this.municipalitiesDict[municipality.id] = municipality;
        this.municipalitiesSource.next(this.getMunicipalitiesFromState());
    }

    public updateMunicipalites(municipalities: MunicipalityModel[]): void {
        this.updateMunicipalitiesToState(municipalities);
        this.municipalitiesSource.next(this.getMunicipalitiesFromState());
    }

    private setMunicipalitiesToState(municipalities: MunicipalityModel[]): void {
        this.municipalitiesDict = {};

        municipalities.forEach(
            (municipality: MunicipalityModel) => this.municipalitiesDict[municipality.id] = { ...municipality }
        );
    }

    private updateMunicipalitiesToState(municipalities: MunicipalityModel[]): void {
        municipalities.forEach(
            (municipality: MunicipalityModel) => this.municipalitiesDict[municipality.id] = { ...municipality }
        );
    }

    private getMunicipalitiesFromState(): MunicipalityModel[] {
        return Object.values(this.municipalitiesDict);
    }
}
