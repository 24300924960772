import { Injectable } from "@angular/core";
import { UserModel } from "../models";
import { UserService } from "../data-access/services";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserFacade {
    public users$: Observable<UserModel[]>;

    private usersSource = new ReplaySubject<UserModel[]>(1);

    constructor(
        private userService: UserService
    ) {
        this.users$ = this.usersSource.asObservable();
    }

    public loadUsers(): void {
        this.userService.getUsers().subscribe(
            (users: UserModel[]) => {
                const collator = new Intl.Collator('de');
                const compareFn = (a: UserModel, b: UserModel): number => collator.compare(a.givenName, b.givenName);

                this.usersSource.next([...users].sort(compareFn));
            }
        );
    }

    public assignDepartment(departmentId: string): Observable<UserModel> {
        return this.userService.assignDepartment(departmentId);
    }
}
