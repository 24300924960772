import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ConcessionContractModel } from '../../../../core/models';
import { ConcessionContractDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class ConcessionContractService {
    constructor(
        private http: HttpClient,
    ) {
    }

    public getMunicipalityConcessionContracts(municipalityId: string): Observable<ConcessionContractModel[]> {
        const url = `${environment.portalApiUrl}/concession-contracts?municipalityId=${municipalityId}`;

        return this.http.get<ConcessionContractDto[]>(url).pipe(
            map((dtos: ConcessionContractDto[]) => dtos.map((dto: ConcessionContractDto) => this.mapFromDto(dto)))
        );
    }

    private mapFromDto(dto: ConcessionContractDto): ConcessionContractModel {
        return {
            id: dto.id,
            commodity: dto.commodity,
            concessionContractKey: dto.concessionContractKey
        };
    }
}
