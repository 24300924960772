import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CpAuthorizationResolver } from '@concession-portal/ng-dso-ui-components-fe';


export const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: (): any => import('./view/layout/layout.view.module').then((m) => m.LayoutViewModule),
    },
    {
        path: 'error',
        loadChildren: (): any => import('./view/error/error.view.module').then(m => m.ErrorViewModule),
        data: { errorType: 'error' },
    },
    {
        path: 'unauthorized',
        loadChildren: (): any => import('./view/error/error.view.module').then(m => m.ErrorViewModule),
        data: { errorType: 'unauthorized' },
    },
    {
        path: 'callback',
        resolve: { authorizationResolver: CpAuthorizationResolver },
        loadChildren: (): any => import('./view/login/login.view.module').then(m => m.LoginViewModule),
    },
    {
        path: '**', redirectTo: '', pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
