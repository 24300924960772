import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CpToasterService } from '@concession-portal/ng-dso-ui-components-fe';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ActivityModel, ProcedureModel } from 'src/app/core/models';
import { PHASE } from '../../../../core/enums';
import { ActivityFacade, ProcedureFacade } from '../../../../core/facades';
import { getActiveActivityPhases, getPhaseName } from '../../../../core/utils/phase.util';

@Component({
  selector: 'portal-end-phase-dialog',
  templateUrl: './end-phase-dialog.component.html',
  styleUrls: ['./end-phase-dialog.component.scss', '../../styles/phase-transition.scss']
})
export class EndPhaseDialogComponent implements OnInit {

  public phaseName: string;

  private ngDestroy: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string; currentPhase: PHASE; updatedOn: string },
    private dialogRef: MatDialogRef<EndPhaseDialogComponent>,
    private activityFacade: ActivityFacade,
    private procedureFacade: ProcedureFacade,
    private toasterService: CpToasterService
  ) {
  }

  public ngOnInit(): void {
    this.phaseName = getPhaseName(this.data.currentPhase);
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
}

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onEndPhase(): void {
    const call: Observable<ActivityModel | ProcedureModel> = getActiveActivityPhases().includes(this.data.currentPhase)
      ? this.activityFacade.endPhase(this.data.id)
      : this.procedureFacade.endPhase(this.data.id);

    call.pipe(takeUntil(this.ngDestroy)).subscribe({
      next: () =>  {
          this.toasterService.success('Die Phase wurde erfolgreich beendet.');
          this.dialogRef.close();
      },
      error: () => {
          this.toasterService.error('Die Phase konnte nicht beendet werden.');
      }
    });
  }
}
