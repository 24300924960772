import { Injectable } from "@angular/core";
import { KeyAccountManagerModel, NetCenterManagerModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class KeyAccountManagerState {
    public keyAccountManagers$: Observable<KeyAccountManagerModel[]>;

    private keyAccountManagersSource = new ReplaySubject<KeyAccountManagerModel[]>(1);
    private keyAccountManagersDict: TDictionary<KeyAccountManagerModel>;

    constructor() {
        this.keyAccountManagersDict = {};
        this.keyAccountManagers$ = this.keyAccountManagersSource.asObservable();
    }

    public setKeyAccountManagers(keyAccountManagers: KeyAccountManagerModel[]): void {
        this.keyAccountManagersToState(keyAccountManagers);
        this.keyAccountManagersSource.next(this.keyAccountManagersFromState());
    }

    public setKeyAccountManager(keyAccountManager: KeyAccountManagerModel): void {
        this.keyAccountManagersDict[keyAccountManager.id] = keyAccountManager;
        this.keyAccountManagersSource.next(this.keyAccountManagersFromState());
    }

    public addKeyAccountManager(keyAccountManager: KeyAccountManagerModel): void {
        this.keyAccountManagersDict[keyAccountManager.id] = keyAccountManager;
        this.keyAccountManagersSource.next(this.keyAccountManagersFromState());
    }

    private keyAccountManagersToState(keyAccountManagers: KeyAccountManagerModel[]): void {
        this.keyAccountManagersDict = {};

        keyAccountManagers.forEach(
            (keyAccountManager: NetCenterManagerModel) => this.keyAccountManagersDict[keyAccountManager.id] = { ...keyAccountManager }
        );
    }

    private keyAccountManagersFromState(): KeyAccountManagerModel[] {
        return Object.values(this.keyAccountManagersDict);
    }
}
