import { Injectable } from "@angular/core";
import { ConsultantModel } from "../models";
import { ConsultantService } from "../data-access/services";
import { Observable, map, tap } from "rxjs";
import { ConsultantState } from "../states";
import { sortEntities } from "../utils/sort.util";

@Injectable({ providedIn: 'root' })
export class ConsultantFacade {
    public consultants$: Observable<ConsultantModel[]>;

    constructor(
        private consultantService: ConsultantService,
        private consultantState: ConsultantState
    ) {
        this.consultants$ = this.consultantState.consultants$.pipe(
            map((consultants: ConsultantModel[]) => sortEntities<ConsultantModel>(consultants))
        );
    }

    public loadConsultants(): void {
        this.consultantService.getConsultants().subscribe(
            (consultants: ConsultantModel[]) => {
                this.consultantState.setConsultants(consultants)
            }
        );
    }

    public createConsultant(consultant: ConsultantModel): Observable<ConsultantModel> {
        return this.consultantService.createConsultant(consultant).pipe(
            tap((consultant: ConsultantModel) => this.consultantState.addConsultant(consultant))
        );
    }
}
