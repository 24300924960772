export interface KeyAccountManagerModel {
    id?: string;
    kid: string;
    departmentId: string;
    description?: string;
    givenName: string;
    lastName: string;
    phoneNumber?: string;
    mobileNumber?: string;
    faxNumber?: string;
    email?: string;
}

export const EMPTY_KEY_ACCOUNT_MANAGER: KeyAccountManagerModel = {
    kid: null,
    departmentId: null,
    description: null,
    givenName: null,
    lastName: null,
    phoneNumber: null,
    mobileNumber: null,
    faxNumber: null,
    email: null
};
