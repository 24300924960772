import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PERMISSION } from '../../../../core/enums';


@Injectable({ providedIn: 'root' })
export class PermissionService {

    constructor(private http: HttpClient) {  }

    public getPortalOperationPermissions(): Observable<PERMISSION[]> {
        return this.http.get<PERMISSION[]>(`${environment.portalApiUrl}/permissions`);
    }
}
