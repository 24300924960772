import { Injectable } from "@angular/core";
import { NetCenterManagerModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { TDictionary } from "../utils/dictionary.util";

@Injectable({ providedIn: 'root' })
export class NetCenterManagerState {
    public netCenterManagers$: Observable<NetCenterManagerModel[]>;

    private netCenterManagersSource = new ReplaySubject<NetCenterManagerModel[]>(1);
    private netCenterManagersDict: TDictionary<NetCenterManagerModel>;

    constructor() {
        this.netCenterManagersDict = {};
        this.netCenterManagers$ = this.netCenterManagersSource.asObservable();
    }

    public setNetCenterManagers(netCenterManagers: NetCenterManagerModel[]): void {
        this.netCenterManagersToState(netCenterManagers);
        this.netCenterManagersSource.next(this.netCenterManagersFromState());
    }

    public setNetCenterManager(netCenterManager: NetCenterManagerModel): void {
        this.netCenterManagersDict[netCenterManager.id] = netCenterManager;
        this.netCenterManagersSource.next(this.netCenterManagersFromState());
    }

    public addNetCenterManager(netCenterManager: NetCenterManagerModel): void {
        this.netCenterManagersDict[netCenterManager.id] = netCenterManager;
        this.netCenterManagersSource.next(this.netCenterManagersFromState());
    }

    private netCenterManagersToState(netCenterManagers: NetCenterManagerModel[]): void {
        this.netCenterManagersDict = {};

        netCenterManagers.forEach(
            (netCenterManager: NetCenterManagerModel) => this.netCenterManagersDict[netCenterManager.id] = { ...netCenterManager }
        );
    }

    private netCenterManagersFromState(): NetCenterManagerModel[] {
        return Object.values(this.netCenterManagersDict);
    }
}
