import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CP_APPLICATION, CpAuthService } from '@concession-portal/ng-dso-ui-components-fe';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { PROCEDURE_TITLE_VALIDATOR_PATTERN } from '../../../../constants';
import { PHASE } from '../../../../core/enums';
import { ActivityFacade, ConsultantFacade, MunicipalityFacade, ProcedureFacade } from '../../../../core/facades';
import { SelectItem } from '../../../../core/items';
import { ActivityModel, ConsultantModel, MunicipalityModel, PhaseModel, ProcedureModel } from '../../../../core/models';
import { getCommodityName } from '../../../../core/utils/commodity.util';
import { procedureTitleAlreadyExists } from '../../../../core/utils/form-validator.util';
import { filterSelectItems, mapToSelectItems } from '../../../../core/utils/item.util';


@Component({
    selector: 'portal-start-procedure-phase',
    templateUrl: './start-procedure-phase.component.html',
    styleUrls: ['./start-procedure-phase.component.scss', '../../styles/phase-transition.scss'],
})
export class StartProcedurePhaseComponent implements OnInit, OnDestroy {
    @Input()
    public data: PhaseModel;

    @Output()
    public dataUpdated = new EventEmitter<PhaseModel>();

    @Output()
    public dataValid = new EventEmitter<boolean>();

    public consultantItems$: Observable<SelectItem[]>;
    public procedureItems$: Observable<SelectItem[]>;
    public activityItems$: Observable<SelectItem[]>;
    public activityText$: Observable<string>;
    public hasKosmosAccess: boolean;
    public hasRobinAccess: boolean;

    public form: FormGroup;

    private applyConsultantsFilter = new BehaviorSubject<string>('');
    private applyProceduresFilter = new BehaviorSubject<string>('');
    private applyActivitiesFilter = new BehaviorSubject<string>('');

    private ngDestroy = new Subject<void>();

    constructor(
        private procedureFacade: ProcedureFacade,
        private activityFacade: ActivityFacade,
        private consultantFacade: ConsultantFacade,
        private municipalityFacade: MunicipalityFacade,
        private authService: CpAuthService,
    ) {
        this.hasKosmosAccess = this.authService.hasApplicationAccess(CP_APPLICATION.KOSMOS);
        this.hasRobinAccess = this.authService.hasApplicationAccess(CP_APPLICATION.ROBIN);

        this.form = new FormGroup({
            consultantId: new FormControl<string>(null, [Validators.required]),
            title: new FormControl<string>(null, [Validators.required, Validators.pattern(PROCEDURE_TITLE_VALIDATOR_PATTERN)]),
            activityIds: new FormControl<string[]>([], [Validators.required]),
            basedOnProcedureId: new FormControl<string>(null),
            suspectedCompetitor: new FormControl<string>(null, [Validators.maxLength(100)]),
        });

        this.consultantFacade.loadConsultants();
        this.procedureFacade.loadProcedures();
        this.activityFacade.loadActivities();
        this.municipalityFacade.loadMunicipalities();
    }

    public ngOnInit(): void {
        this.setConsultantItems();
        this.setProcedureItems();
        this.setActivityItems();

        this.initFormGroup();
        this.bindFormChanges();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public filterConsultants(filter: string): void {
        this.applyConsultantsFilter.next(filter);
    }

    public filterProcedures(filter: string): void {
        this.applyProceduresFilter.next(filter);
    }

    public filterActivityList(filter: string): void {
        this.applyActivitiesFilter.next(filter);
    }

    private initFormGroup(): void {
        this.form.controls.consultantId.setValue(this.data.procedure?.consultantId, { emitEvent: false });
        this.form.controls.title.setValue(this.data.procedure?.title ?? this.data.municipalityName, { emitEvent: false });
        this.form.controls.activityIds.setValue(this.data.procedure?.activityIds, { emitEvent: false });
        this.form.controls.basedOnProcedureId.setValue(this.data.procedure?.basedOnProcedureId, { emitEvent: false });
        this.form.controls.suspectedCompetitor.setValue(this.data.procedure?.suspectedCompetitor, { emitEvent: false });

        this.form.controls.title.markAsTouched();

        if (this.hasKosmosAccess) {
            this.form.controls.activityIds.disable()
        }

        this.dataValid.emit(this.form.valid);
    }

    private setConsultantItems(): void {
        this.consultantItems$ = this.applyConsultantsFilter.pipe(
            switchMap((filter: string) =>
                combineLatest([
                    this.consultantFacade.consultants$,
                    of(filter)
                ])
            ),
            map(([consultants, filter]: [ConsultantModel[], string]) => ([
                mapToSelectItems<ConsultantModel>(consultants),
                filter.toLowerCase()
            ])),
            map(([items, filter]: [SelectItem[], string]) => filterSelectItems(items, filter)),
        );
    }

    private setProcedureItems(): void {
        this.procedureItems$ = this.applyProceduresFilter.pipe(
            switchMap((filter: string) =>
                combineLatest([
                    this.procedureFacade.procedures$,
                    this.consultantFacade.consultants$,
                    of(filter)
                ])
            ),
            map(([procedures, consultants, filter]: [ProcedureModel[], ConsultantModel[], string]) => {
                const consultantDict: {[consultantId: string]: ConsultantModel} = {};
                consultants.forEach((c: ConsultantModel) => consultantDict[c.id] = c);

                const existingProcedureTitles: string[] = procedures.filter(
                    (p: ProcedureModel) => p.commodity === this.data.commodity
                ).map((p: ProcedureModel) => p.title);

                this.form.controls.title.setAsyncValidators([procedureTitleAlreadyExists(existingProcedureTitles)]);

                return [
                    procedures.map((p: ProcedureModel) => ({ value: p.id, text: `${p.title} - ${consultantDict[p.consultantId].name}` } as SelectItem)),
                    filter.toLowerCase()
                ];
            }),
            map(([items, filter]: [SelectItem[], string]) => filterSelectItems(items, filter))
        );
    }

    private setActivityItems(): void {
        this.activityItems$ = this.applyActivitiesFilter.pipe(
            switchMap((filter: string) =>
                combineLatest([
                    this.activityFacade.activities$,
                    this.municipalityFacade.municipalities$,
                    of(filter)
                ])
            ),
            map(([activities, municipalities, filter]: [ActivityModel[], MunicipalityModel[], string]) => {
                const municipalityDict: {[municipalityId: string]: MunicipalityModel} = {};
                municipalities.forEach((m: MunicipalityModel) => municipalityDict[m.id] = m);

                const filteredActivities = activities.filter(
                    (a: ActivityModel) => (
                        [PHASE.PUBLICATION_END, PHASE.QUANTITY_STRUCTURE_CREATION_END].includes(a.phase)
                        && a.commodity === this.data.commodity
                    )
                );

                return [
                    filteredActivities.map((a: ActivityModel) => ({
                        value: a.id,
                        text: `${municipalityDict[a.municipalityId].name} - ${getCommodityName(a.commodity)} (${municipalityDict[a.municipalityId].municipalityKey})`
                    } as SelectItem)),
                    filter.toLowerCase()
                ];
            }),
            map(([items, filter]: [SelectItem[], string]) => filterSelectItems(items, filter))
        );
    }

    private bindFormChanges(): void {
        this.form.valueChanges.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe(() => {
            const data: PhaseModel = {
                ...this.data,
                procedure: {
                    consultantId: this.form.controls.consultantId.value,
                    title: this.form.controls.title.value,
                    suspectedCompetitor: this.form.controls.suspectedCompetitor.value,
                    activityIds: this.form.controls.activityIds.value,
                    basedOnProcedureId: this.form.controls.basedOnProcedureId.value
                }
            };

            this.dataUpdated.emit(data);
            this.dataValid.emit(this.form.valid);
        });
    }
}
