import { Injectable } from "@angular/core";
import { FederalStateModel } from "../models";
import { Observable, ReplaySubject } from "rxjs";
import { sortEntities } from "../utils/sort.util";
import { FederalStateService } from "../data-access/services";

@Injectable({ providedIn: 'root' })
export class FederalStateFacade {
    public federalStates$: Observable<FederalStateModel[]>;

    private federalStatesSource = new ReplaySubject<FederalStateModel[]>(1);

    constructor(
        private federalStateService: FederalStateService,
    ) {
        this.federalStates$ = this.federalStatesSource.asObservable();
    }

    public loadFederalStates(): void {
        this.federalStateService.getFederalStates().subscribe(
            (federalStates: FederalStateModel[]) => this.federalStatesSource.next(sortEntities<FederalStateModel>(federalStates))
        );
    }
}
