export interface DepartmentModel {
    id?: string;
    abbreviation: string;
    name: string;
}

export const EMPTY_DEPARTMENT: DepartmentModel = {
    id: null,
    abbreviation: '',
    name: ''
};

export const DEPARTMENT_ID_ASSIGNMENT_MISSING = '01010070-0008-0090-1007-000000000001';
