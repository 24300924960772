
export interface IBuildInfo {
    version: string;
    revision: string;
    branch: string;
    builddate: string;
}

// Auto-generated file by @eon-is/eon-buildinfo package
export const buildInfo: IBuildInfo = {
    version: '',
    revision: '',
    branch: '',
    builddate: '',
};

