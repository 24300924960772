import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, map } from "rxjs";
import { ConcessionContractService } from "../data-access/services";
import { COMMODITY } from "../enums";
import { ConcessionContractModel } from "../models";

@Injectable({ providedIn: 'root' })
export class ConcessionContractFacade {

    public concessionContracts$: Observable<ConcessionContractModel[]>;

    private concessionContractsSource = new ReplaySubject<ConcessionContractModel[]>(1);

    constructor(private concessionContractService: ConcessionContractService) {
        this.concessionContracts$ = this.concessionContractsSource.asObservable();
    }

    public loadMunicipalityConcessionContracts(municipalityId: string, commodity: COMMODITY): void {
        this.concessionContractService.getMunicipalityConcessionContracts(municipalityId).pipe(
            map((concessionContracts: ConcessionContractModel[]) => concessionContracts.filter(
                (cc: ConcessionContractModel) => cc.commodity === commodity)
            )
        ).subscribe(
            (concessionContracts: ConcessionContractModel[]) => this.concessionContractsSource.next(concessionContracts)
        );
    }
}
