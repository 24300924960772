import { PHASE, COMMODITY, STATE } from "../enums";


export interface ActivityModel {
    id?: string;
    ownerKid: string;
    phase: PHASE;
    gridOperatorId: string;
    endDate: string;
    commodity?: COMMODITY;
    phaseStartDate?: string;
    penaltyMax?: number;
    state?: STATE;
    municipalityId?: string;
    municipalityName?: string;
    concessionContractId?: string;
    concessionContractKey?: string;
    notes?: string;
}
