import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { EMPTY_USER, UserModel } from '../../../../core/models';
import { UserDto } from '../../dtos';


@Injectable({
    providedIn: 'root',
})
export class UserService {

    private cachedUsers: UserModel[];

    constructor(private http: HttpClient) {}

    public getUser(kid: string): Observable<UserModel> {
        return this.http.get<UserModel>(
            `${environment.portalApiUrl}/users/${kid}`
        ).pipe(
            catchError(() => of(EMPTY_USER))
        );
    }

    public getUsers(): Observable<UserModel[]> {
        return this.cachedUsers
            ? of(this.cachedUsers)
            : this.http.get<UserDto[]>(
                    `${environment.portalApiUrl}/users`
            ).pipe(
                map((dtos: UserDto[]) => dtos.map((dto: UserDto) => this.mapFromDto(dto))),
                tap((users: UserModel[]) => this.cachedUsers = users),
            );
    }

    public assignDepartment(departmentId: string): Observable<UserModel> {
        return this.http.put<UserModel>(
            `${environment.portalApiUrl}/current-user/department`,
            { departmentId },
        ).pipe(
            map((dto: UserDto) => this.mapFromDto(dto)),
            tap(() => this.cachedUsers = undefined)
        );
    }

    private mapFromDto(dto: UserDto): UserModel {
        return {
            kid: dto.kid,
            givenName: dto.givenName,
            lastName: dto.lastName,
            department: dto.department
                ? {
                    id: dto.department.id,
                    abbreviation: dto.department.abbreviation,
                    name: dto.department.name
                }
                : null
        }
    }
}
