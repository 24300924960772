export enum STATE {
    ACTIVE = 'ACTIVE',
    DORMANT = 'DORMANT',
    LEGAL_ISSUES = 'LEGAL_ISSUES',
}


export class ActivityMetaStateLabelMapper {
    // Map the given commodity enum value to the corresponding icon label
    public static mapMetaStateLabel(state: STATE): string {
        switch (state) {
            case STATE.ACTIVE:
                return 'aktiv';
            case STATE.DORMANT:
                return 'ruhend';
            case STATE.LEGAL_ISSUES:
                return 'jur. Klärung';
            default:
                return '';
        }
    }

    // Map the given commodity enum value to the associated icon value
    public static mapMetaStateIcon(state: STATE): string {
        switch (state) {
            case STATE.ACTIVE:
                return 'activity';
            case STATE.DORMANT:
                return 'sleepy';
            case STATE.LEGAL_ISSUES:
                return 'paragraph';
            default:
                return '';
        }
    }
}
